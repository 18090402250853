import IconPurchasing from "../../assets/icons/purchasing.svg";
import IconInventory from "../../assets/icons/inventory.svg";
import IconSales from "../../assets/icons/sales.svg";
import IconReport from "../../assets/icons/report.svg";

export const navItems1 = [
    {
        title: "Purchasing",
        icon: IconPurchasing,
        items: [
            {
                text: 'Purchase Orders',
                page: '/purchase-orders'
            },
            {
                text: 'Suppliers',
                page: '/suppliers'
            },
            {
                text: 'Supplier Groups',
                page: '/supplier-groups'
            }
        ]
    },
    {
        title: "Inventory",
        icon: IconInventory,
        items: [
            {
                text: 'Items',
                page: '/items'
            },
            {
                text: 'Item Groups',
                page: '/item-groups'
            },
            {
                text: 'Stock Transfers',
                page: '/stock-transfers'
            },
            {
                text: 'Unit of Measures',
                page: '/unit-of-measures'
            },
            {
                text: 'Item Taxes',
                page: '/item-taxes'
            },
            {
                text: 'Locations',
                page: '/locations'
            },
            
        ]
    },
    {
        title: "Sales",
        icon: IconSales,
        items: [
            {
                text: 'Sales Orders',
                page: '/sales-orders'
            },
            {
                text: 'Customers',
                page: '/customers'
            },
            {
                text: 'Customer Groups',
                page: '/customer-groups'
            }
        ]
    },
]

export const navItems2 = [
    {
        title: "Report",
        icon: IconReport,
        items: [
            {
                text: 'Sales Report',
                page: '/sales-reports'
            },
            {
                text: 'Inventory Report',
                page: '/inventory-reports'
            },
            {
                text: 'Purchasing Report',
                page: '/purchase-reports'
            }
        ]
    }
]




import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from '../../../pages/home/home.component';
import Logout from '../../logout/logout.component';
import ProtectedHeader from '../protected-header/protected-header.componenet';
import ProtectedFooter from '../protected-footer/protected-footer.componenet';
import LoggedInTest from '../../../pages/log-in/logged-in-test';
import ProtectedRoute from '../protected-route/protected-route.componenet';
import DetailPrintDisplay from '../../../pages/detail-print/detail-print-display.component';
import ListItemTaxes from '../../../pages/item-taxes/list-item-taxes.componenet';
import DetailItemTax from '../../../pages/item-taxes/detail-item-tax/detail-item-tax.component';
import ListItem from '../../../pages/items/list-items.componenet';
import DetailItem from '../../../pages/items/detail-item/detail-item.component';
import Company from '../../../pages/company/company.component';
import CompanyForm from '../../../pages/company/company-form/company-form.component';
import AccountsList from '../../../pages/account/account-list/accounts-list.componenet';
import AccountForm from '../../../pages/account/account-form/account-form.component';
import PurchaseOrdersList from '../../../pages/purchase-order/purchase-order-list/purchase-orders-list.componenet';
import PurchaseOrderForm from '../../../pages/purchase-order/purchase-order-form/purchase-order-form.component';
import PurchaseOrderDocument from '../../../pages/purchase-order/purchase-order-document/purchase-order-document.componenet';
import SalesOrdersList from '../../../pages/sales-order/sales-order-list/sales-orders-list.componenet';
import SalesOrderForm from '../../../pages/sales-order/sales-order-form/sales-order-form.component';
import SalesOrderDocument from '../../../pages/sales-order/sales-order-document/sales-order-document.componenet';
import SalesInvoiceDocument from '../../../pages/sales-invoice/sales-invoice-document/sales-invoice-document.componenet';
import SalesReceiptDocument from '../../../pages/sales-receipt/sales-receipt-document/sales-receipt-document.componenet';
import PickDocument from '../../../pages/pick/pick-document/pick-document.componenet';
import PackingSlipDocument from '../../../pages/pack/packing-slip-document/packing-slip-document.componenet';
import BoxLabelDocument from '../../../pages/pack/box-label-document/box-label-document.componenet';
import CreditNoteDocument from '../../../pages/credit-note/credit-note-document/credit-note-document.componenet';
import PurchaseReceiveDocument from '../../../pages/receive/purchase-receive-document/purchase-receive-document.componenet';
import PurchaseReturnDocument from '../../../pages/purchase-return/purchase-return-document/purchase-return-document.componenet';
import PurchaseInvoiceDocument from '../../../pages/purchase-invoice/purchase-invoice-document.componenet';
import ItemsList from '../../../pages/item/item-list/items-list.componenet';
import ItemForm from '../../../pages/item/item-form/item-form.component';
import StockTransferList from '../../../pages/stock-transfer/stock-transfer-list/stock-transfers-list.componenet';
import StockTransferForm from '../../../pages/stock-transfer/stock-transfer-form/stock-transfer-form.component';
import StockTransferDocument from '../../../pages/stock-transfer/stock-transfer-document/stock-transfer-document.componenet';
import ItemGroupsList from '../../../pages/item-group/item-group-list/item-groups-list.componenet';
import ItemGroupForm from '../../../pages/item-group/item-group-form/item-group-form.component';
import UnitOfMeasuresList from '../../../pages/unit-of-measure/unit-of-measure-list/unit-of-measures-list.componenet';
import UnitOfMeasureForm from '../../../pages/unit-of-measure/unit-of-measure-form/unit-of-measure-form.component';
import LocationsList from '../../../pages/location/location-list/locations-list.componenet';
import LocationForm from '../../../pages/location/location-form/location-form.component';
import SupplierGroupsList from '../../../pages/supplier-group/supplier-group-list/supplier-groups-list.componenet';
import SupplierGroupForm from '../../../pages/supplier-group/supplier-group-form/supplier-group-form.component';
import CustomerGroupsList from '../../../pages/customer-group/customer-group-list/customer-groups-list.componenet';
import CustomerGroupForm from '../../../pages/customer-group/customer-group-form/customer-group-form.component';
import SupplierList from '../../../pages/supplier/supplier-list/suppliers-list.componenet';
import SupplierForm from '../../../pages/supplier/supplier-form/supplier-form.component';
import CustomersList from '../../../pages/customer/customer-list/customers-list.componenet';
import CustomerForm from '../../../pages/customer/customer-form/customer-form.component';
import ItemTaxList from '../../../pages/item-tax/item-tax-list/item-taxes-list.componenet';
import ItemTaxForm from '../../../pages/item-tax/item-tax-form/item-tax-form.component';
import SalesReport from '../../../pages/sales-report/sales-report.componenet';
import PurchaseReport from '../../../pages/purchase-report/purchase-report.componenet';
import InventoryReport from '../../../pages/inventory-report/inventory-report.componenet';
import DepartmentList from '../../../pages/department/department-list/departments-list.componenet';
import DepartmentForm from '../../../pages/department/department-form/department-form.component';
import DesignationList from '../../../pages/designation/designation-list/designations-list.componenet';
import DesignationForm from '../../../pages/designation/designation-form/designation-form.component';
import EmployeeList from '../../../pages/employee/employee-list/employees-list.componenet';
import EmployeeForm from '../../../pages/employee/employee-form/employee-form.component';
import AccessDenied from '../../../pages/access-denied/access-denied.component';
import Help from '../../../pages/help/help.component';

const ProtectedLayout = ({
    noNavs
}) => <>
        {!noNavs && <ProtectedHeader />}
        <Switch>
            <ProtectedRoute exact path="/home" component={Home} appName={false} />
            <ProtectedRoute exact path="/logout" component={Logout} />
            <ProtectedRoute exact path="/help" component={Help} />
            <Route path='/access-denied' component={AccessDenied} />
       
            <ProtectedRoute exact path="/loggedintest" component={LoggedInTest} />
            <ProtectedRoute exact path="/detail-print" component={DetailPrintDisplay} />
            <ProtectedRoute exact path="/list-item-taxes" component={ListItemTaxes} />
            <ProtectedRoute exact path="/detail-item-tax" component={DetailItemTax} />
            <ProtectedRoute exact path="/list-items" component={ListItem} />
            <ProtectedRoute exact path="/detail-item" component={DetailItem} />

            <ProtectedRoute exact path="/company" component={Company} key={Date.now()} appName={`company`} />
            <ProtectedRoute exact path="/company/new" component={CompanyForm} key={Date.now()} isNew={true} appName={`company`} />
            <ProtectedRoute exact path="/company/:uuid" component={CompanyForm} key={Date.now()} appName={`company`} />
       
            <ProtectedRoute exact path="/accounts" component={AccountsList} key={Date.now()} appName={`account`} />
            <ProtectedRoute exact path="/accounts/new" component={AccountForm} key={Date.now()} isNew={true} appName={`account`} />
            <ProtectedRoute exact path="/accounts/:uuid" component={AccountForm} key={Date.now()} appName={`account`} />

            <ProtectedRoute exact path="/suppliers" component={SupplierList} key={Date.now()} appName={`supplier`} />
            <ProtectedRoute exact path="/suppliers/new" component={SupplierForm} isNew={true} key={Date.now()} appName={`supplier`} />
            <ProtectedRoute exact path="/suppliers/:uuid" component={SupplierForm} key={Date.now()} appName={`supplier`} />

            <ProtectedRoute exact path="/items" component={ItemsList} key={Date.now()} appName={`item`}/>
            <ProtectedRoute exact path="/items/new" component={ItemForm} isNew={true} key={Date.now()} appName={`item`}/>
            <ProtectedRoute exact path="/items/:uuid" component={ItemForm} key={Date.now()} appName={`item`}/>

            <ProtectedRoute exact path="/item-groups" component={ItemGroupsList} key={Date.now()} appName={`item_group`}/>
            <ProtectedRoute exact path="/item-groups/new" component={ItemGroupForm} isNew={true} key={Date.now()} appName={`item_group`}/>
            <ProtectedRoute exact path="/item-groups/:uuid" component={ItemGroupForm} key={Date.now()} appName={`item_group`}/>

            <ProtectedRoute exact path="/unit-of-measures" component={UnitOfMeasuresList} key={Date.now()} appName={`unit_of_measure`} />
            <ProtectedRoute exact path="/unit-of-measures/new" component={UnitOfMeasureForm} key={Date.now()} isNew={true} appName={`unit_of_measure`} />
            <ProtectedRoute exact path="/unit-of-measures/:uuid" component={UnitOfMeasureForm} key={Date.now()} appName={`unit_of_measure`} />

            <ProtectedRoute exact path="/locations" component={LocationsList} key={Date.now()} appName={`location`}/>
            <ProtectedRoute exact path="/locations/new" component={LocationForm} isNew={true} key={Date.now()} appName={`location`}/>
            <ProtectedRoute exact path="/locations/:uuid" component={LocationForm} key={Date.now()} appName={`location`}/>

            <ProtectedRoute exact path="/supplier-groups" component={SupplierGroupsList} key={Date.now()} appName={`supplier_group`} />
            <ProtectedRoute exact path="/supplier-groups/new" component={SupplierGroupForm} isNew={true} key={Date.now()} appName={`supplier_group`} />
            <ProtectedRoute exact path="/supplier-groups/:uuid" component={SupplierGroupForm} key={Date.now()} appName={`supplier_group`} />

            <ProtectedRoute exact path="/customer-groups" component={CustomerGroupsList} key={Date.now()} appName={`customer_group`}/>
            <ProtectedRoute exact path="/customer-groups/new" component={CustomerGroupForm} isNew={true} key={Date.now()} appName={`customer_group`}/>
            <ProtectedRoute exact path="/customer-groups/:uuid" component={CustomerGroupForm} key={Date.now()} appName={`customer_group`}/>

            <ProtectedRoute exact path="/customers" component={CustomersList} key={Date.now()} appName={`customer`}/>
            <ProtectedRoute exact path="/customers/new" component={CustomerForm} isNew={true} key={Date.now()} appName={`customer`}/>
            <ProtectedRoute exact path="/customers/:uuid" component={CustomerForm} key={Date.now()} appName={`customer`}/>

            <ProtectedRoute exact path="/purchase-orders" component={PurchaseOrdersList} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/new" component={PurchaseOrderForm} isNew={true} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid" component={PurchaseOrderForm} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/documents" component={PurchaseOrderDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/receiving-notes/documents" component={PurchaseReceiveDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/purchase-returns/documents" component={PurchaseReturnDocument} key={Date.now()} appName={`purchase_order`} />
            <ProtectedRoute exact path="/purchase-orders/:uuid/purchase-invoices/documents" component={PurchaseInvoiceDocument} key={Date.now()} appName={`purchase_order`} />

            <ProtectedRoute exact path="/sales-orders" component={SalesOrdersList} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/new" component={SalesOrderForm} isNew={true} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid" component={SalesOrderForm} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/documents" component={SalesOrderDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/invoices/documents" component={SalesInvoiceDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/receipts/documents" component={SalesReceiptDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/picks/documents" component={PickDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/packing-slips/documents" component={PackingSlipDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/box-labels/documents" component={BoxLabelDocument} key={Date.now()} appName={`sales_order`}/>
            <ProtectedRoute exact path="/sales-orders/:uuid/credit-note/documents" component={CreditNoteDocument} key={Date.now()} appName={`sales_order`}/>

            <ProtectedRoute exact path="/stock-transfers" component={StockTransferList} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/new" component={StockTransferForm} isNew={true} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/:uuid" component={StockTransferForm} key={Date.now()} appName={`stock_transfer`}/>
            <ProtectedRoute exact path="/stock-transfers/:uuid/documents" component={StockTransferDocument} key={Date.now()} appName={`stock_transfer`}/>

            <ProtectedRoute exact path="/item-taxes" component={ItemTaxList} key={Date.now()} appName={`item_tax`}/>
            <ProtectedRoute exact path="/item-taxes/new" component={ItemTaxForm} isNew={true} key={Date.now()} appName={`item_tax`}/>
            <ProtectedRoute exact path="/item-taxes/:uuid" component={ItemTaxForm} key={Date.now()} appName={`item_tax`}/>

            <ProtectedRoute exact path="/sales-reports" component={SalesReport} key={Date.now()} appName={`report`}/>
            <ProtectedRoute exact path="/purchase-reports" component={PurchaseReport} key={Date.now()} appName={`report`}/>
            <ProtectedRoute exact path="/inventory-reports" component={InventoryReport} key={Date.now()} appName={`report`}/>
        </Switch>
        {!noNavs && <ProtectedFooter />}
    </>

export default ProtectedLayout;
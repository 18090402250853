import React, { useRef, useMemo } from 'react';
import {
    useTable,
    useResizeColumns,
    useBlockLayout,
    useSortBy,
    useRowSelect,
    useGlobalFilter,
    useRowState,
    useFlexLayout
} from 'react-table';

import './form-table.styles.scss';

import { useFormContext, Controller } from "react-hook-form";
import TableCheckbox from '../table-checkbox/table-checkbox.componenet';
import FormTableButton from '../form-table-button/form-table-button.component';
import { addRowFormTable, removeRemoveTableRows, setFormTableLatestData, setValueEntredBlur } from '../../redux/form/form.actions';
import { connect } from 'react-redux';
import { useState } from 'react';
import { selectFormEdit, selectFormTableLatestData, selectFormTableLatestRows, selectRemoveTableRow, selectSelectedFieldValue } from '../../redux/form/form.reselect';
import { createStructuredSelector } from 'reselect';
import { useEffect } from 'react';
import FormProgress from '../form-progress/form-progress.componenets';
import { deleteItems, removeDeletedItems } from '../../redux/delete/delete.actions';
import { authTokenSelector } from '../../redux/user/user.reselect';
import { withRouter } from 'react-router-dom';
import useFetchAutoFill from '../../effects/use-fetch-autofill';
import Spinner from '../spinner/spinner.component';

const FormTable = ({
    title,
    tableColumns,
    formTableData,
    hasNoAction,
    hasProgress,
    hasAutoFillButton,
    fieldName,
    removeTableRow,
    tableLatestRows,
    setFormTableLatestData,
    editSectionData,
    formEdit,
    selectedFieldValue,
    endpoint,
    authToken,
    deleteItems,
    removeRemoveTableRows,
    setValueEntredBlur,
    isViewOnly
}) => {
    const { resetField, setValue, reset } = useFormContext();
    const columns = useMemo(() => tableColumns, [tableColumns]);

    const [data, setData] = useState(formTableData);
    const [isAutoFill, setIsAutoFill] = useState(false);

    const authTokenFromState = authToken;
    const autoFillUrl = `${endpoint}/autofill`;
    const { autoFillData, autoFillError, isAutoFillLoading } = useFetchAutoFill(
        autoFillUrl,
        authTokenFromState,
        setIsAutoFill,
        isAutoFill
    )

    const tableMethods = useTable({
        columns,
        data
    },
        useGlobalFilter,
        useResizeColumns,
        useFlexLayout,
        useRowSelect,
        useRowState
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,

    } = tableMethods;

    const handleAddMoreItem = (event) => {
        event.preventDefault();
        setData(prevData => {
            const lastId = prevData.length ? prevData[prevData.length - 1].id : 0;
            const newFormTableDataObject = { ...formTableData[0] };
            newFormTableDataObject.id = (lastId + 1);
            const newFormTableDataArray = [newFormTableDataObject]
            return (
                [...prevData, ...newFormTableDataArray]
            )
        });
    }

    const handleAutoFill = (event) => {
        event.preventDefault();
        setIsAutoFill(true)
    }

    useEffect(() => {
        if (formEdit && !isAutoFillLoading) {
            setValueEntredBlur(true);
        }
    }, [isAutoFillLoading])

    useEffect(() => {
        if (removeTableRow.length) {
            const idx = parseInt(removeTableRow[0]);
            if (tableLatestRows[removeTableRow[0]]) {
                Object.keys(tableLatestRows[removeTableRow[0]]).forEach(key => {
                    const fieldItemName = `${fieldName}.${removeTableRow[0]}.${key}`;
                    resetField(fieldItemName);
                })

                const formInlineData = [...tableLatestRows];
                formInlineData.splice(idx, 1)

                formInlineData.forEach((row, index) => {
                    Object.entries(row).forEach(([key, value]) => {
                        const name = `${[fieldName]}.${index}.${key}`;
                        setValue(name, value)
                    })
                })

                const lastPrevRowIndex = (tableLatestRows.length - 1)
                Object.keys(tableLatestRows[lastPrevRowIndex]).forEach(key => {
                    const lastFieldItemName = `${fieldName}.${lastPrevRowIndex}.${key}`;
                    resetField(lastFieldItemName);
                })
            };

            const rows = [...data];
            rows.splice(idx, 1)
            setData(rows);

            removeRemoveTableRows();
            setValueEntredBlur(true);
        }
    }, [removeTableRow]);

    useEffect(() => {
        if (formEdit) {
            editSectionData = autoFillData.length ?
                Object.keys(editSectionData[0]).length ?
                    editSectionData :
                    [] :
                editSectionData
                
            autoFillData.length && autoFillData.forEach(autoFillData => {
                const isNotEqual = editSectionData.every(editSectionData => {
                    if (autoFillData.Item) {
                        return autoFillData.Item.value != editSectionData.Item.value
                    } else if (autoFillData.Location) {
                        return autoFillData.Location.value != editSectionData.Location.value
                    }
                })

                if (isNotEqual) {
                    editSectionData.push(autoFillData)
                }
            });

            editSectionData.forEach((row, index) => {
                Object.entries(row).forEach(([key, value]) => {
                    const name = `${[fieldName]}.${index}.${key}`;
                    setValue(name, value)
                })
            })

            const rows = editSectionData.reduce(prevData => {
                const lastId = prevData.length ? prevData[prevData.length - 1].id : 0;
                const newFormTableDataObject = { ...formTableData[0] };
                newFormTableDataObject.id = (lastId + 1);
                const newFormTableDataArray = [newFormTableDataObject]
                return (
                    [...prevData, ...newFormTableDataArray]
                )
            }, []);

            setData(rows);
        }
    }, [editSectionData, autoFillData]);

    useEffect(() => {
        if (selectedFieldValue && selectedFieldValue.value) {
            const selectedNameInArray = selectedFieldValue.name.split(".");
            selectedNameInArray.splice(2, 1);

            const namePath = selectedNameInArray.join(".")

            Object.entries(selectedFieldValue.value).map(([key, value]) => {
                const name = `${namePath}.${key}`;
                setValue(name, value);
            })

        }
    }, [selectedFieldValue]);

    useEffect(() => {
        return () => {
            reset({ items: "" });
        }
    }, [])

    return (
        <div className="form-table-container">
            <div className="form-table-header">
                {title}
            </div>
            <div className="form-table-wrapper">
                <table className="form-table" {...getTableProps()} >
                    <thead className="form-table__header">
                        {headerGroups.map(headerGroup => (
                            <tr className="form-table__header__group" {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th
                                        className="form-table__header__item"
                                        {...column.getHeaderProps([

                                            {
                                                className: column.className,
                                                style: column.style
                                            }
                                        ])}
                                    >
                                        <span>
                                            {column.render('Header')}
                                        </span>
                                        <span style={{ border: '3px solid coral', width: '3px', height: '40px' }}
                                            {...column.getResizerProps()}
                                            className={`form-table__header__item__resizer 
                                                ${column.isResizing ?
                                                    "form-table__header__item__resizer--isResizing" : ""
                                                }`}
                                        />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className="form-table__body" {...getTableBodyProps()}>
                        {rows.map(row => {
                            row.isDisable = isViewOnly
                            prepareRow(row)
                            return (
                                <tr className="form-table__body__row" {...row.getRowProps()} >
                                    {row.cells.map(cell => (
                                        <td
                                            className={`form-table__body__row__cell
                                            ${hasNoAction ? null : "form-table__body__row__cell--action"}
                                            `}
                                            {...cell.getCellProps([
                                                {
                                                    className: cell.column.className,
                                                    style: cell.column.style
                                                }
                                            ])}
                                            data-label={cell.render('Header')}
                                        >
                                            <span className="form-table__body__row__cell__header">
                                                {cell.render('Header')}
                                            </span>
                                            <span className="form-table__body__row__cell__data">
                                                {cell.render('Cell')}
                                            </span>
                                        </td>
                                    ))}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className="form-table-footer">
                {!hasNoAction && <FormTableButton
                    handleClick={(event) => { handleAddMoreItem(event) }}
                >
                    Add Row
                </FormTableButton>}

                {hasAutoFillButton && !isViewOnly && <FormTableButton
                    handleClick={(event) => { handleAutoFill(event) }}
                    hasLeftMargin={true}
                >
                    Auto Fill
                    {
                        isAutoFillLoading && <Spinner size="small" />
                    }
                </FormTableButton>}



                {hasProgress && <div className="mt-4">
                    <FormProgress />
                </div>}

            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    authToken: authTokenSelector,
    removeTableRow: selectRemoveTableRow,
    // tableLatestData: selectFormTableLatestData,
    tableLatestRows: selectFormTableLatestRows,
    formEdit: selectFormEdit,
    selectedFieldValue: selectSelectedFieldValue,
    authToken: authTokenSelector,
})

const mapDispatchToProps = (dispatch) => ({
    addRowFormTable: () =>
        dispatch(addRowFormTable()),
    setFormTableLatestData: (data) =>
        dispatch(setFormTableLatestData(data)),
    removeRemoveTableRows: () =>
        dispatch(removeRemoveTableRows()),
    setValueEntredBlur: (data) =>
        dispatch(setValueEntredBlur(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FormTable)

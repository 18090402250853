export const LOCAL_FILTER_FIELDS = [
    {
        label: "Item Group Code",
        type: "text",
        name: "itemGroupCode",
    },
    {
        label: "Item Group Name",
        type: "text",
        name: "itemGroupName",
    }
]
import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import TableCellStatus from '../../../components/table-cell-status/table-cell-status.component';
import { displayCellValue } from '../../../utils/column-values.util';

export const BEGINNING_STOCK_INFO = {
  formTitle: "Item",
  path: "/items",
  subPath: "/beginningstock",
  listPage: "/items",
}

export const BEGINNING_STOCK_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 1,
      fieldName: "item",
      fieldItems: [
        {
          label: "Item Code",
          type: "text",
          name: "itemCode",
          initialFocus: true,
          validationProps: {
            required: "Item code is required"
          },
          disabled: true
        },
        {
          label: "Item Name",
          type: "text",
          name: "itemName",
          initialFocus: true,
          validationProps: {
            required: "Item name is required"
          },
          disabled: true
        }
      ]
    },
    tableForm: {
      sectionType: "Beginning Stock Details",
      sectionTypeId: "tableForm",
      fieldName: "items",
      hasNoAction: false,
      hasProgress: false,
      hasAutoFillButton: true,
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          Cell: ({ row }) => Number(row.id) + 1,
          width: 30,
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Location"}
                required={false}
              />
            )
          },
          accessor: "location",
          width: 160,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";
            const validationProps = {
              required: "Location is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"Location"}
              rowNumber={row.id}
              value={row.value}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Beginning Qty"}
                required={false}
              />
            )
          },
          accessor: "beginningQty",
          width: 160,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"beginningQty"}
              rowNumber={row.id}
              valueType={"number"}
              validationProps={false}
              align="right"
              disabled={row.isDisable}
            />)
          }
        },
        {
          id: "action",
          width: 70,
          Cell: ({ row, toggleRowSelected }) => {
            const uuid = row.original.uuid;
            return (<FormTableInlineDelete
              rowNumber={row.id}
              toggleRowSelected={toggleRowSelected}
            />)
          }
        }
      ]
    },
    totalDetails: {
      sectionType: "Total",
      sectionTypeId: "totalDetails",
      multiForm: false,
      expandable: false,
      isExpand: true,
      addButton: true,
      position: "LAST",
      breakPoint: 0,
      fieldName: "total",
      fieldItems: [
        {
          label: "Beginning Qty",
          type: "text",
          name: "totalQty",
          initialFocus: false,
          disabled: true
        }
      ],
    }
  },
  watchFields: []
}

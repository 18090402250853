import { format } from 'date-fns';
import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
import FormTableDatePicker from '../../../components/form-table-date-picker/form-table-date-picker.component';
import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';
import { displayCellValue } from '../../../utils/column-values.util';

export const SHIP_INFO = {
  formTitle: "Sales Order",
  path: "/salesorders",
  subPath: "/shippings",
  listPage: "/sales-orders",
}

export const SHIP_FORM_TEMPLATE = {
  bodySections: {
    basic: {
      sectionType: "basic",
      sectionTypeId: "BASIC",
      expandable: false,
      breakPoint: 2,
      fieldName: "salesOrder",
      fieldItems: [
        {
          label: "SO Number",
          type: "text",
          name: "salesOrderNumber",
          initialFocus: true,
          disabled: true,
          defaultValue: `SO-.YYYY.-`
        },
        {
          label: "Customer",
          type: "select",
          name: "Customer",
          required: false,
          initialFocus: false,
          disabled: true,
          muliselect: false,
          url: "/customers",
          validationProps: {
            required: "Customer is required"
          }
        },
        {
          label: "SO Date",
          type: "date",
          name: "salesOrderDate",
          initialFocus: false,
          disabled: true,
          validationProps: {
            required: "SO date is required"
          }
        },
        // {
        //   label: "Assign To",
        //   type: "select",
        //   name: "User",
        //   required: false,
        //   initialFocus: false,
        //   disabled: true,
        //   muliselect: false,
        //   url: "/users"
        // }
      ]
    },
    tableForm: {
      sectionType: "Items",
      sectionTypeId: "tableForm",
      fieldName: "items",
      hasNoAction: true,
      hasProgress: false,
      tableColumns: [
        {
          Header: "No.",
          id: "count",
          width: 40,
          Cell: ({ row }) => {
            return (
              Number(row.id) + 1
            )
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Box Number"}
                required={true}
              />
            )
          },
          accessor: "boxNumber",
          width: 100,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"boxNumber"}
              rowNumber={row.id}
              valueType={"number"}
              align="left"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Ship Date"}
                required={true}
              />
            )
          },
          accessor: "shipDate",
          width: 60,
          Cell: ({ row }) => {
            const endpointUrl = "/locations";
            const validationProps = {
              required: "Location is required"
            }

            return (<FormTableDatePicker
              fieldName={"items"}
              fieldItemName={"shippingDate"}
              rowNumber={row.id}
              value={row.value}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Carrier"}
                required={false}
              />
            )
          },
          accessor: "carrier",
          width: 80,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"shippingCarrier"}
              rowNumber={row.id}
              valueType={"text"}
              align="left"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Tracking Number"}
                required={false}
              />
            )
          },
          accessor: "trackingNumber",
          width: 120,
          Cell: ({ row }) => {
            return (<FormTableTextField
              fieldName={"items"}
              fieldItemName={"trackingNumber"}
              rowNumber={row.id}
              valueType={"text"}
              align="left"
              disabled={row.isDisable}
            />)
          }
        },
        {
          Header: () => {
            return (
              <FormTableHeaderCell
                text={"Ship Status"}
                required={true}
              />
            )
          },
          accessor: "shippingStatus",
          width: 100,
          Cell: ({ row }) => {
            const endpointUrl = "/shippingstatuses";
            const validationProps = {
              required: "Ship Status is required"
            }

            return (<FormTableSelect
              fieldName={"items"}
              fieldItemName={"shippingStatus"}
              rowNumber={row.id}
              value={row.value}
              defaultValue={{value: 1, label: "Not Shipped"}}
              endpointUrl={endpointUrl}
              validationProps={validationProps}
              disabled={row.isDisable}
            />)
          }
        }
      ]
    },
  },
  watchFields: []
}

import { format } from 'date-fns';
import TableCellStatus from '../../components/table-cell-status/table-cell-status.component';
import { displayCellValue, displayCellValueNumberToTwoDecimal } from '../../utils/column-values.util';
// import FormTableCalculateDisplay from '../../../components/form-table-calculate/form-table-calculate.component';
// import FormTableHeaderCell from '../../../components/form-table-header-cell/form-table-header-cell.component';
// import FormTableInlineDelete from '../../../components/form-table-inline-delete/form-table-inline-delete.component';
// import FormTableSelect from '../../../components/form-table-select/form-table-select.component';
// import FormTableTextField from '../../../components/form-table-text-field/form-table-text-field.component';

export const SALES_REPORT_BY_PROIFT_INFO = {
  reportTitle: "Sales Reports",
  path: "/salesreports",
  subPath: "/salesbyprofit",
  listPage: "/sales-reports",
  hasChart: true,
  tableHasGrandTotal: true,
}

export const SALES_REPORT_BY_PROIFT_COLUMN_TEMPLATE = [
  {
    Header: "SO Number",
    accessor: "salesOrderNumber",
    Footer: 'Grand Total'
  },
  {
    Header: "Inventory Status",
    accessor: "inventoryStatus",
    Cell: ({ value }) => {
      if (!value) {
        return displayCellValue(value);
      }

      return (
        <TableCellStatus
          value={value}
        />
      )
    }
  },
  {
    Header: "Customer",
    accessor: "customerName",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },
  {
    Header: "Order Date",
    accessor: "salesOrderDate",
    Cell: ({ value }) => {
      return displayCellValue(value);
    }
  },

  {
    Header: "Total Sales (ETB)",
    accessor: "totalSales",
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandTotalSales'
  },
  {
    Header: "Cost of Goods Sold (ETB)",
    accessor: "costOfGoodsSold",
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    },
    Footer: 'grandcostOfGoodsSold'
  },
  {
    Header: "Gross Profit (ETB)",
    accessor: "grossProfit",
    Cell: ({ value }) => {
      return displayCellValueNumberToTwoDecimal(value);
    }
  },

]

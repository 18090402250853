import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUser } from "../../redux/user/user.reselect";
import "./account-display.styles.scss";

const AccountDisplay = ({ user }) => {
    return (
        user &&
        <div className="account-display">
            <div className="account-display__icon">
                {Array.from(user.username)[0]}
            </div>
            <div className="account-display__content">
                <span className="account-display__content__username">
                    {user.username}
                </span>
                <span className="account-display__content__email">
                    {user.email}
                </span>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    user: selectUser,
});

export default connect(mapStateToProps)(AccountDisplay);
